import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import { Container } from "reactstrap"
import MySwiper from "../components/swiper/swiper"
// import SampleForm from "../components/form/form"
// import { LazyLoadImage } from "react-lazy-load-image-component"
// import "react-lazy-load-image-component/src/effects/blur.css"
// import bsns from "../images/business.svg"
// import finance from "../images/financial.svg"
// import jigsaw from "../images/jigsaws.svg"
// import report from "../images/report.svg"
// import trophy from "../images/trophy.svg"

class IndexPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    const posts = this.props.data.posts.edges
    // console.log(posts)
    const reviews = this.props.data.testimonials.edges
    // console.log("reviews", reviews)

    const isOdd = num => num % 2 === 1

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div id="hero">
          <div className="container">
            <div className="hero-image">
              <img src="assets/Group-54.png" alt="imgtext" />
            </div>
            <div className="row">
              <div className="col-md-5"></div>
              <div className="col-md-7">
                <div>
                  <h1 className="hero-title">Accounting as a Service</h1>
                  <h4 className="hero-subtitle">
                    Find out how we can reduce your accounting burden while
                    providing financial insight into your business.
                  </h4>
                  <a className="hero-cta" href="#">
                    Show Me How ➜
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="frontline">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="frontline-content">
                  <div className="row">
                    <div className="col-md-6">
                      <h2 className="frontline-title">
                        Frontline
                        <br />
                        Partners
                      </h2>
                    </div>
                    <div className="col-md-6">
                      <p className="mt-3 mb-4">
                        is an{" "}
                        <span className="text-blue font-700">accounting</span>,{" "}
                        <span className="text-blue font-700">tax</span> and{" "}
                        <span className="text-blue font-700">
                          business advisory
                        </span>{" "}
                        firm that provides services to small to mid-sized
                        businesses utilizing technology to provide actionable
                        financial data to maximize profits and create a growth
                        path to achieve client&#39;s goals.
                      </p>
                      <a href="#" className="blue-btn text-blue font-700">
                        Learn More ➜
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="index">
          <section id="whatwedo">
            <div className="container">
              <div className="row">
                <div className="col-md-5">
                  <h2 className="section-title-left text-blue">What We Do</h2>
                  <p className="section-p mb-4">
                    We provide the needed accounting support to run and grow
                    your business.
                    <br />
                    <br />
                    We offer <strong>Accounting as a Service</strong> in a
                    flexible format to reduce client&#39;s cost and increase
                    profits while providing personal attention in an expedient
                    and professional manner that maximizes value.
                  </p>
                  <a href="#" className="blue-btn text-blue font-700">
                    Meet our top of the line accountants ➜
                  </a>
                </div>
                <div className="col-md-7">
                  <div className="accountant-list mt-4">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="accountant-info">
                          <div className="box">
                            <div className="imgBox">
                              <img
                                src="assets/agent.png"
                                className="img-fluid"
                                alt="imgtext"
                              />
                            </div>
                            <div className="content">
                              <h4>John Doe</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="accountant-info">
                          <div className="box">
                            <div className="imgBox">
                              <img
                                src="assets/agent2.png"
                                className="img-fluid"
                                alt="imgtext"
                              />
                            </div>
                            <div className="content">
                              <h4>Cheska Marie Ivankav</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="accountant-info">
                          <div className="box">
                            <div className="imgBox">
                              <img
                                src="assets/agent3.png"
                                className="img-fluid"
                                alt="imgtext"
                              />
                            </div>
                            <div className="content">
                              <h4>John Doe</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="services">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="services-content">
                    <h2 className="section-title text-blue text-center mb-5">
                      Our Services
                    </h2>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="service-item text-center">
                          <img
                            style={{ maxWidth: "150px" }}
                            className="service-image mb-3"
                            src="assets/Mask%20Group%20142.png"
                            alt="imgtext"
                          />
                          <h4 className="service-title text-blue">
                            Accounting
                          </h4>
                          <p className="section-p font-300">
                            Flexible services from bookkeeping through CFO and
                            any variation in between to produce GAAP financial
                            statements and other financial statements to meet
                            our client's needs.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="service-item text-center">
                          <img
                            style={{ maxWidth: "150px" }}
                            className="service-image mb-3"
                            src="assets/Mask%20Group%20143.png"
                            alt="imgtext"
                          />
                          <h4 className="service-title text-blue">Taxation</h4>
                          <p className="section-p font-300">
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy eirmod tempor invidunt ut
                            labore et dolore magna aliquyam erat, sed diam
                            voluptua.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="service-item text-center">
                          <img
                            style={{ maxWidth: "150px" }}
                            className="service-image mb-3"
                            src="assets/Mask%20Group%20144.png"
                            alt="imgtext"
                          />
                          <h4 className="service-title text-blue">
                            Business Advisory
                          </h4>
                          <p className="section-p font-300">
                            Business consulting in HR, Contract Management, IT
                            services and other special situations.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="mission">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 p-0">
                  <div className="mission-img h-100"></div>
                </div>
                <div className="col-md-6 p-0">
                  <div className="mission-content">
                    <h2 className="section-title-left text-blue">
                      Our Mission
                    </h2>
                    <p className="section-p">
                      To help our clients identify their business and financial
                      needs and provide our expertise to achieve their financial
                      goals utilizing technology and automation while
                      maintaining personal, professional, proactive services.
                    </p>
                    <a href="#" className="blue-btn text-blue font-700">
                      About Us ➜
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="vision">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 p-0">
                  <div className="mission-content">
                    <h2 className="section-title-left text-blue">Our Vision</h2>
                    <p className="section-p">
                      To bring together world-class accounting and business
                      talent to provide preeminent Accounting as a Service to
                      small and mid-sized businesses that allows clients to
                      maximize their value while exceeding their goals.
                    </p>
                    <a href="#" className="blue-btn text-blue font-700">
                      About Us ➜
                    </a>
                  </div>
                </div>
                <div className="col-md-6 p-0">
                  <div className="vision-img h-100"></div>
                </div>
              </div>
            </div>
          </section>
          <section id="believe">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="services-content">
                    <h2 className="section-title text-blue text-center mb-5">
                      We Believe In
                    </h2>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="service-item text-center">
                          <img
                            style={{ maxWidth: "150px" }}
                            className="service-image mb-3"
                            src="assets/strategy.png"
                            alt="imgtext"
                          />
                          <h4 className="service-title text-blue">
                            Right Strategy
                          </h4>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="service-item text-center">
                          <img
                            style={{ maxWidth: "150px" }}
                            className="service-image mb-3"
                            src="assets/execute.png"
                            alt="imgtext"
                          />
                          <h4 className="service-title text-blue">
                            Perfect Execution
                          </h4>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="service-item text-center">
                          <img
                            style={{ maxWidth: "150px" }}
                            className="service-image mb-3"
                            src="assets/success.png"
                            alt="imgtext"
                          />
                          <h4 className="service-title text-blue">
                            Absolute Success
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {posts.map((post, index) => {
            return (
              <section key={index} id="article">
                <div className="container-fluid">
                  <div className="row">
                    <div
                      className={`col-md-6 p-0 ${
                        isOdd(index) ? "order-2" : null
                      }`}
                    >
                      <div
                        className="article-img h-100"
                        style={{
                          backgroundImage: `url(/${
                            post.node.frontmatter.banner !== null
                              ? post.node.frontmatter.banner
                              : "https://source.unsplash.com/user/joshhild/500x500"
                          })`,
                        }}
                      ></div>
                    </div>
                    <div className="col-md-6 p-0">
                      <div className="mission-content">
                        <h2 className="section-title-left text-blue">
                          {post.node.frontmatter.title}
                        </h2>
                        <p className="section-p">
                          {post.node.frontmatter.excerpt}
                        </p>
                        <a
                          href={post.node.fields.slug}
                          className="blue-btn text-blue font-700"
                        >
                          Read More ➜
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )
          })}
          {/* <section id="article-2">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 p-0">
                  <div className="mission-content">
                    <h2 className="section-title-left text-blue">Article Title</h2>
                    <p className="section-p">
                      To bring together world-class accounting and business
                      talent to provide preeminent Accounting as a Service to
                      small and mid-sized businesses that allows clients to
                      maximize their value while exceeding their goals.
                    </p>
                    <a href="#" className="blue-btn text-blue font-700">
                      Read More ➜
                    </a>
                  </div>
                </div>
                <div className="col-md-6 p-0">
                  <div className="article-2-img h-100"></div>
                </div>
              </div>
            </div>
          </section> */}
          <section id="growing">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="growing-content">
                    <h2 className="section-title text-blue text-center mb-5">
                      We Are Growing
                    </h2>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="growing-item text-center">
                          <h3 className="growing-number text-blue">1,456</h3>
                          <h5 className="growing-category text-blue">
                            Partners
                          </h5>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="growing-item text-center">
                          <h3 className="growing-number text-blue">12</h3>
                          <h5 className="growing-category text-blue">
                            Office Locations
                          </h5>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="growing-item text-center">
                          <h3 className="growing-number text-blue">1,234</h3>
                          <h5 className="growing-category text-blue">
                            Testimonials
                          </h5>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="growing-item text-center">
                          <h3 className="growing-number text-blue">9.3%</h3>
                          <h5 className="growing-category text-blue">
                            Satisfactory Rate
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="reviews">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-5">
                  <div className="reviews-content h-100">
                    <h2 className="section-title text-blue text-center">
                      Customer Reviews
                    </h2>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="reviews-slide">
                    <MySwiper slidesperview="2">
                      {reviews.map((review, index) => {
                        return (
                          <div key={index}>
                            <div className="reviews-item">
                              <img
                                src="assets/Ellipse%2026.png"
                                className="review-author-image"
                                alt="imgtext"
                              />
                              <h4 className="review-author">
                                {review.node.frontmatter.name}
                              </h4>
                              <h6 className="review-position">
                                {review.node.frontmatter.position}
                              </h6>
                              <p className="review-body">
                                {review.node.frontmatter.testimonial}
                              </p>
                            </div>
                          </div>
                        )
                      })}

                      {/* <div>
                        <div className="reviews-item">
                          <img
                            src="assets/Ellipse%2026.png"
                            className="review-author-image"
                            alt="imgtext"
                          />
                          <h4 className="review-author">John Doe</h4>
                          <h6 className="review-position">Manager Director</h6>
                          <p className="review-body">
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy eirmod tempor invidunt ut
                            labore et dolore magna aliquyam erat, sed diam
                            voluptua. At vero eos et accusam et justo duo
                            dolores et ea
                          </p>
                        </div>
                      </div> */}
                      {/* <div>
                        <div className="reviews-item">
                          <img
                            src="assets/Ellipse%2026.png"
                            className="review-author-image"
                            alt="imgtext"
                          />
                          <h4 className="review-author">John Doe</h4>
                          <h6 className="review-position">Manager Director</h6>
                          <p className="review-body">
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy eirmod tempor invidunt ut
                            labore et dolore magna aliquyam erat, sed diam
                            voluptua. At vero eos et accusam et justo duo
                            dolores et ea
                          </p>
                        </div>
                      </div> */}
                    </MySwiper>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="partners">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="partners-content">
                    <h2 className="section-title text-blue text-center mb-5">
                      Our Partners
                    </h2>
                    <div className="row">
                      <div className="col-md-3 col-sm-6 col-6">
                        <div className="partners-item text-center">
                          <a href="#">
                            <img
                              className="logo img-fluid"
                              src="assets/logo3.png"
                              alt="imgtext"
                            />
                          </a>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 col-6">
                        <div className="partners-item text-center">
                          <a href="#">
                            <img
                              className="logo img-fluid"
                              src="assets/logo4.png"
                              alt="imgtext"
                            />
                          </a>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 col-6">
                        <div className="partners-item text-center">
                          <a href="#">
                            <img
                              className="logo img-fluid"
                              src="assets/logo5.png"
                              alt="imgtext"
                            />
                          </a>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 col-6">
                        <div className="partners-item text-center">
                          <a href="#">
                            <img
                              className="logo img-fluid"
                              src="assets/logo2.png"
                              alt="imgtext"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/*        <div className="blog-section bg-light">
          <div className="container">
            <div className="section-heading text-center">
              <h6 className="font-weight-bold text-uppercase flair">blog</h6>
              <h2>Latest Posts</h2>
            </div>
            <div className="row justify-content-around py-5">
              {posts.map(post => (
                <div className="col-md-6 col-lg-4 mb-4" key={post.node.id}>
                  <div className="blog-item bg-white h-100">
                    <Link to={post.node.slug.current}>
                      <div
                        className="blog-image"
                        style={{
                          backgroundImage: `url(${
                            post.node.mainImage !== null
                              ? post.node.mainImage.asset.fluid.src
                              : "https://source.unsplash.com/user/joshhild/500x500"
                          })`,
                        }}
                      />
                    </Link>
                    <div className="blog-text">
                      <Link to={post.node.slug.current}>
                        <h4>{post.node.title}</h4>
                      </Link>
                      <p className="pt-2 text-muted">{post.node.excerpt}</p>
                      <span className="text-muted small">
                        <i className="fa fa-calendar-o pr-1" />
                        {post.node.publishedAt}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center">
              <Link className="btn btn-primary" role="button" to="/blog">
                More from the Blog
              </Link>
            </div>
          </div>
        </div>*/}
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    posts: allMarkdownRemark(
      limit: 2
      filter: { fileAbsolutePath: { regex: "/(posts)/" } }
      sort: { fields: frontmatter___publishedDate, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            banner
            excerpt
          }
        }
      }
    }
    testimonials: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(testimonials)/" } }
    ) {
      edges {
        node {
          frontmatter {
            name
            photo
            position
            testimonial
          }
        }
      }
    }
  }
`
